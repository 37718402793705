import { React, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import environments from "../environments";
import { CopyBlock, dracula } from "react-code-blocks";
import axios from "axios";
import { UserPermissions } from "./UserPermissions";

const Show = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [publisher, setPublisher] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const environment = process.env.REACT_APP_ENVIRONMENT || "development";
  const bonbonApiDomain = environments[environment].REACT_APP_BONBON_API;
  const { pubId } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getPublisher = async () => {
      const pubResponse = await axios.get(
        `${bonbonApiDomain}/api/v1/publishers/${pubId}`
      );
      setPublisher(pubResponse.data);
    };
    getPublisher();
  }, [bonbonApiDomain, pubId]);

  useEffect(() => {
    const getAccessToken = async () => {
      if(!user) {
        navigate('/');
      }
      if(!user.email.endsWith("bonbon.tech")) {
        navigate('/account');
      }
      const token = await getAccessTokenSilently({
        audience: environments[environment].REACT_APP_AUDIENCE,
        scope: "read:current_user update:current_user_metadata",
      });
      setAccessToken(token);
    };
    getAccessToken();
  });

  const setupEmailUnsubscribeGroup = async () => {
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/sendGridAsm`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
  };

  const setupDynamicConfig = async () => {
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/dynamicConfig`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
  };

  const setupAutotune = async () => {
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/autotune`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
  };

  const setupGoogleAnalytics = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/gaProperty`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
    return false;
  };

  const setupAWSCertificate = async () => {
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/acm`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
  };

  const setupAuth0 = async () => {
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/auth0`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
  };

  const setupSegmentSource = async () => {
    setSubmitting(true);
    const pubResponse = await axios.post(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/segmentSource`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    setSubmitting(false);
    setPublisher(pubResponse.data);
  };

  if (publisher && accessToken) {
    return (
      <Box
        sx={{
          borderRadius: theme.spacing(5),
          backgroundColor: `${theme.palette.primary.background}`,
          p: theme.spacing(7),
          my: theme.spacing(7),
          display: "flex",
          maxWidth: "592px",
        }}
      >
        <Box
          sx={{
            border: `solid 1px ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            p: theme.spacing(5, 8),
            width: "100%",
          }}
        >
          <h1>Publisher</h1>
          <h2>{publisher.pub_name}</h2>
          <h2>{publisher.domain}</h2>
          <img src={publisher.logo_url} alt={publisher.pub_name} width="100" />
          <UserPermissions pubId={pubId} />
          {publisher.auth0_client_id && (
            <div>
              <h3>Authentication Setup Complete</h3>
              <p>{publisher.auth0_client_id}</p>
            </div>
          )}
          {!publisher.auth0_client_id && (
            <div>
              <h3>Authentication Setup Incomplete</h3>
              <Button
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{
                  fontWeight: "bold",
                  verticalAlign: "center",
                }}
                onClick={setupAuth0}
              >
                Setup Authentication
                <Typography sx={{ pl: theme.spacing(2) }} component="span">
                  &#x2794;
                </Typography>
              </Button>
            </div>
          )}
          {publisher.sg_sup_id && (
            <div>
              <h3>Email Setup Complete</h3>
              <p>{publisher.sg_sup_id}</p>
            </div>
          )}
          {!publisher.sg_sup_id && (
            <div>
              <h3>Email Setup Incomplete</h3>
              <Button
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{
                  fontWeight: "bold",
                  verticalAlign: "center",
                }}
                onClick={setupEmailUnsubscribeGroup}
              >
                Setup Email Unsubscribe Group
                <Typography sx={{ pl: theme.spacing(2) }} component="span">
                  &#x2794;
                </Typography>
              </Button>
            </div>
          )}
          {publisher.ga_id && (
            <div>
              <h3>Google Analytics Setup Complete</h3>
              <p>{publisher.ga_id}</p>
            </div>
          )}
          {!publisher.ga_id && (
            <div>
              <h3>Google Analytics Setup Incomplete</h3>
              <Button
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{
                  fontWeight: "bold",
                  verticalAlign: "center",
                }}
                onClick={setupGoogleAnalytics}
              >
                Setup Google Analytics
                <Typography sx={{ pl: theme.spacing(2) }} component="span">
                  &#x2794;
                </Typography>
              </Button>
            </div>
          )}
          {publisher.segment_source_id && (
            <div>
              <h3>Segment Source Setup Complete</h3>
              <p>{publisher.segment_source_id}</p>
            </div>
          )}
          {!publisher.segment_source_id && (
            <div>
              <h3>Segment Source Setup Incomplete</h3>
              <Button
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{
                  fontWeight: "bold",
                  verticalAlign: "center",
                }}
                onClick={setupSegmentSource}
              >
                Setup Segment Source
                <Typography sx={{ pl: theme.spacing(2) }} component="span">
                  &#x2794;
                </Typography>
              </Button>
            </div>
          )}
          {publisher.has_dynamic_config && (
            <h3>Dynamic Config Setup Complete</h3>
          )}
          {!publisher.has_dynamic_config && (
            <div>
              <h3>Dynamic Config Setup Incomplete</h3>
              {!publisher.ga_id && (
                <p>Setup GA to enable Dynamic Config Setup</p>
              )}
              {!publisher.segment_source_id && (
                <p>Setup Segment Source to enable Dynamic Config Setup</p>
              )}
              {publisher.ga_id && publisher.segment_source_id && (
                <Button
                  variant="contained"
                  size="medium"
                  disabled={submitting}
                  sx={{
                    fontWeight: "bold",
                    verticalAlign: "center",
                  }}
                  onClick={setupDynamicConfig}
                >
                  Setup Dynamic Config
                  <Typography sx={{ pl: theme.spacing(2) }} component="span">
                    &#x2794;
                  </Typography>
                </Button>
              )}
            </div>
          )}
          {publisher.has_autotune && <h3>Autotune Setup Complete</h3>}
          {!publisher.has_autotune && (
            <div>
              <h3>Autotune Setup Incomplete</h3>
              <Button
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{
                  fontWeight: "bold",
                  verticalAlign: "center",
                }}
                onClick={setupAutotune}
              >
                Setup Autotune
                <Typography sx={{ pl: theme.spacing(2) }} component="span">
                  &#x2794;
                </Typography>
              </Button>
            </div>
          )}
          {publisher.aws_acm_arn && <h3>AWS Certificate Setup Complete</h3>}
          {!publisher.aws_acm_arn && (
            <div>
              <h3>AWS Certificate Setup Incomplete</h3>
              <Button
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{
                  fontWeight: "bold",
                  verticalAlign: "center",
                }}
                onClick={setupAWSCertificate}
              >
                Setup AWS Certificate
                <Typography sx={{ pl: theme.spacing(2) }} component="span">
                  &#x2794;
                </Typography>
              </Button>
            </div>
          )}
          {publisher.auth0_client_id && (
            <code>
              <CopyBlock
                text={`Here's the tag integration setup guide for ${
                  publisher.domain
                }:

1. Add this script to the head of your site or inject it into your tag manager: <script>function bonbontag(){bonbonDataLayer.push(arguments);};(function(w,d,s,l,i,e){w[l]=w[l]||[];w[l].push({'bonbon.start':new Date().getTime(),event:'bonbon.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.type="text/javascript";j.async=true;j.setAttribute('data-clientId',i);e&&j.setAttribute('data-environment',e);j.id='bonbon-js-sdk';j.src='https://cdn.bonbon.tech/js/bonbon.js';f.parentNode.insertBefore(j,f);})(window,document,'script','bonbonDataLayer','${
                  publisher.auth0_client_id
                }');</script>

${
  publisher.aws_acm_resource_name && publisher.aws_acm_resource_value
    ? `
Optional - To extend cookie life in Safari. Will only work if you're using a reverse proxy like CloudFlare, Akamai, Fastly

2. Create a DNS CNAME record from ${publisher.aws_acm_resource_name} to ${publisher.aws_acm_resource_value}

3. Create a DNS A record from bonbon.${publisher.domain} to 174.129.28.217`
    : ""
}
`}
                language={"javascript"}
                showLineNumbers={false}
                theme={dracula}
                codeBlock
              />
            </code>
          )}
        </Box>
      </Box>
    );
  } else {
    return <ReactLoading type="balls" color="#3443EF" />;
  }
};

//export default Profile;

export default withAuthenticationRequired(Show, {
  onRedirecting: () => <ReactLoading type="balls" />,
});
