import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import ReactLoading from "react-loading";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import environments from "../../environments";

const PauseSwitch = ({ dashboardId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const environment = process.env.REACT_APP_ENVIRONMENT || "development";
  const [isPaused, setIsPaused] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const getPassPercentageFromSettings = (settings) => {
    const { rules } = settings.data;
    const geoRule = rules.filter(
      (r) => r.name === "Geotargeting" || r.name === "US Only"
    )[0];
    return geoRule.passPercentage;
  };

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently({
        audience: environments[environment].REACT_APP_AUDIENCE,
        scope: "read:current_user update:current_user_metadata",
      });
      setToken(accessToken);
    };
    getAccessToken();
  }, [environment, getAccessTokenSilently]);

  useEffect(() => {
    if (token) {
      const getVolume = async () => {
        const settingsResponse = await axios.get(
          `${environments[environment].REACT_APP_BONBON_API}/api/v1/dashboards/${dashboardId}/settings`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setIsPaused(getPassPercentageFromSettings(settingsResponse) === 0);
        setLoading(false);
      };
      setLoading(true);
      getVolume();
    }
  }, [dashboardId, environment, token]);

  const handlePause = async () => {
    if (token) {
      setLoading(true);
      const settingsResponse = await axios.post(
        `${environments[environment].REACT_APP_BONBON_API}/api/v1/dashboards/${dashboardId}/settings`,
        { volume: isPaused ? 100 : 0 },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsPaused(getPassPercentageFromSettings(settingsResponse) === 0);
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <FormControlLabel
          label={isPaused ? "Bonbon is paused" : "Bonbon is running"}
          control={<Switch checked={!isPaused} onChange={handlePause} />}
        />
      )}
      {loading && <ReactLoading type="balls" color="#3443EF" />}
    </>
  );
};

export default PauseSwitch;
