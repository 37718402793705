import { React, useEffect } from "react";
import ReactLoading from 'react-loading';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NewForm from './NewForm';

const New = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth0();
    useEffect(() => {
        const checkUser = async () => {
          if(!user) {
            navigate('/');
          }
          if(!user.email.endsWith("bonbon.tech")) {
            navigate('/account');
          }
        };
        checkUser();
    });
    return (<Box sx={{
        borderRadius: theme.spacing(5),
        backgroundColor: `${theme.palette.primary.background}`,
        p: theme.spacing(7),
        my: theme.spacing(7),
        display: 'flex',
        maxWidth: '592px',
    }}>
        <Box sx={{
            border: `solid 1px ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            p: theme.spacing(5, 8),
            width: '100%',
        }}>
            <Typography variant="h4" component="h1">Create Publisher</Typography>
            <NewForm />
        </Box>
    </Box>);
}

//export default Profile;

export default withAuthenticationRequired(New, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
