import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import environments from '../environments';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';


const Index = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();
    const theme = useTheme();
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
    const bonbonApiDomain = environments[environment].REACT_APP_BONBON_API;
    const auth0Domain = environments[environment].REACT_APP_AUTH_DOMAIN;
    const [dashboards, setDashboards] = useState([]);
    const { user } = useAuth0();
    const [isBonbonUser, setIsBonbonUser] = useState(false);
    useEffect(() => {
        const checkUser = async () => {
          if(user && user.email.endsWith("bonbon.tech")) {
            setIsBonbonUser(true);
          }
        };
        checkUser();
    });
    useEffect(() => {
        const getDashboards = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: environments[environment].REACT_APP_AUDIENCE,
                    scope: 'read:current_user update:current_user_metadata',
                });
                const dashboardResponse = await axios.get(
                    `${bonbonApiDomain}/api/v1/dashboards`,
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                if(dashboardResponse.data.length === 0) {
                    navigate('/');
                    return;
                }
                console.log("dashboard data", dashboardResponse.data);
                setDashboards(dashboardResponse.data);
            } catch (e) {
                console.log(e.message);
                console.log(e.stack);
            }
        };
        getDashboards();
    }, [getAccessTokenSilently, auth0Domain, environment, bonbonApiDomain, navigate]);
    
    if (dashboards.length > 0) {
        return (
            <div id="body" className="status">
                <h1>Your Dashboards</h1>
                <ul>
                {dashboards.map((dashboard) => {
                    return (
                        <li>
                            <Typography
                                component={Link}
                                sx={{color: theme.palette.primary.main, textDecoration: 'none'}}
                                variant="subtitle1"
                                href={`/dashboards/${dashboard.auth0_client_id}/${dashboard.domain}`}
                            >
                                View {dashboard.pub_name}'s Dashboard
                            </Typography>
                            {isBonbonUser && (
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <Typography
                                    component={Link}
                                    sx={{color: theme.palette.primary.main, textDecoration: 'none'}}
                                    variant="subtitle1"
                                    href={`/publishers/${dashboard.pub_id}`}
                                >
                                    edit profile
                                </Typography>
                                </span>
                            )}
                        </li>
                    );
                })}
                </ul>
            </div>
        );   
    } else {
        return (<ReactLoading type="balls" color="#3443EF" />);
    }
}

export default withAuthenticationRequired(Index, {
    onRedirecting: () => <ReactLoading type="balls" />,
});

