import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useAuth0 } from '@auth0/auth0-react';
// import { useTheme } from '@mui/material/styles';
import environments from './environments';
import axios from 'axios';

const environment = process.env.REACT_APP_ENVIRONMENT || "development";

const Cobrand = () => {
	const [publisher, setPublisher] = useState(false);
    const {
        getAccessTokenSilently,
    } = useAuth0();
    const auth0Domain = environments[environment].REACT_APP_AUTH_DOMAIN;
    
    useEffect(() => {
        const emptyPublisher = {
            name: "Bonbon",
            logo: "/bonbon_logo.png"
        };
        const getPublisher = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: environments[environment].REACT_APP_AUDIENCE,
                    scope: 'read:current_user update:current_user_metadata',
                });
                const publisherResponse = await axios.get(
                    `${environments[environment].REACT_APP_BONBON_API}/api/v1/users/publisher`,
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                const pub = publisherResponse.data;
                setPublisher({ ...emptyPublisher, ...pub });
            } catch (e) {
                console.log(e.message);
                console.log(e.stack);
                setPublisher({ ...emptyPublisher });
            }
        };
        getPublisher();
    }, [auth0Domain, getAccessTokenSilently]);
    if (publisher) {
        return (<img alt="Bonbon" width="200px" src={publisher.logo}/>);
    } else {
        return (<ReactLoading type="balls" color="#3443EF" />);
    }
};
export default Cobrand;