import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  TextField,
  Button,
  Typography,
  FormHelperText,
  Box,
  IconButton,
  Dialog,
  CardContent,
  Card,
} from "@mui/material";
import environments from "../environments";
import {Add, Close} from "@mui/icons-material";

export const UserPermissions = ({ pubId }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [permissionedEmails, setPermissionedUsers] = useState([]);

  const environment = process.env.REACT_APP_ENVIRONMENT || "development";

  const handleInput = (e) => setEmail(e.currentTarget.value);

  const fetchPermissions = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      audience: environments[environment].REACT_APP_AUDIENCE,
      scope: "read:current_user update:current_user_metadata",
    });

    const permissionsResponse = await axios.get(
      `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/permissions`,
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );

    return permissionsResponse.data.users.map(u => u.email);
  }, [environment, getAccessTokenSilently, pubId])

  const grantPermissions = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: environments[environment].REACT_APP_AUDIENCE,
        scope: "read:current_user update:current_user_metadata",
      });
      await axios.post(
        `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/permissions/grant`,
        { email },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
    } catch (e) {
      throw e;
    }
  };

  const revokePermissions = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: environments[environment].REACT_APP_AUDIENCE,
        scope: "read:current_user update:current_user_metadata",
      });
      await axios.post(
        `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers/${pubId}/permissions/revoke`,
        { email: confirmDelete },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    const getPermissions = async () => {
      const results = await fetchPermissions();
      if(results?.length > 0) {
        setPermissionedUsers(results);
      }
    };
    getPermissions();
  }, [fetchPermissions]);

  const handleGrantPermissions = async () => {
    try {
      await grantPermissions();
      const results = await fetchPermissions();
      setPermissionedUsers(results);
      setEmail('');
    } catch (e) {
      setError(e);
    }
  };

  const handleRevokePermissions = async () => {
    try {
      await revokePermissions();
      const results = await fetchPermissions();
      setPermissionedUsers(results);
      setConfirmDelete(null);
    } catch (e) {
      setError(e);
    }
  }

  const handleDelete = (email) => () => {
    setConfirmDelete(email);
  }

  const handleCancelDelete = () => {
    setConfirmDelete(null);
  }

  return (
    <>
      <Dialog open={confirmDelete !== null} onClose={handleCancelDelete}>
        <Card>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <Typography variant="h5">Delete User?</Typography>
            <Typography variant="body1">By taking this action, you will remove this users ability to view and edit dashboard settings</Typography>
            <Button fullWidth size="small" color="error" variant="outlined" onClick={handleRevokePermissions}>{`Delete ${confirmDelete}`}</Button>
            <Button fullWidth size="small" variant="text" onClick={handleCancelDelete}>Cancel</Button>
          </CardContent>
        </Card>
      </Dialog>
      <h3>User Permissions</h3>
      {permissionedEmails.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: "column" }}>
          {permissionedEmails.map(email => (
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems:'center',
              marginBottom: '.5em'
            }}>
              <Typography variant="body2">{email}</Typography>
              <IconButton color="error" size="small" onClick={handleDelete(email)}><Close/></IconButton>
            </Box>
          ))}
        </Box>
      )}
      <FormControl sx={{ display: "flex", gap: "1em" }}>
        <TextField label="Email" onChange={handleInput} value={email} />
        {error && <FormHelperText variant="error">{error}</FormHelperText>}
        <Button variant="contained" size="small" onClick={handleGrantPermissions} endIcon={<Add />}>
          Add User
        </Button>
      </FormControl>
    </>
  );
};
