import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import environments from './environments';
import axios from 'axios';

const AuthRouter = () => {
    const navigate = useNavigate();
    const {
        user,
        getAccessTokenSilently,
    } = useAuth0();

    const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
    const auth0Domain = environments[environment].REACT_APP_AUTH_DOMAIN;
    const bonbonApiDomain = environments[environment].REACT_APP_BONBON_API;
    useEffect(() => {
        //getting an error 'consent required'
        const getUserMetadata = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: environments[environment].REACT_APP_AUDIENCE,
                    scope: 'read:current_user update:current_user_metadata',
                });
                const metadataResponse = await axios.get(
                    `https://${auth0Domain}/api/v2/users/${user.sub}`,
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                const dashboardResponse = await axios.get(
                    `${bonbonApiDomain}/api/v1/dashboards`,
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                if(dashboardResponse.data.length > 0) {
                    navigate('/dashboards');
                    return;
                }
                const { user_metadata } = metadataResponse.data;
                if (user_metadata && user_metadata.first_name) {
                    navigate('/account');
                } else {
                    navigate('/account/finalize');
                }
            } catch (e) {
                console.log(e.message);
                console.log(e.stack);
            }
        };
        if (user && user.sub) { getUserMetadata(); }
    }, [getAccessTokenSilently, navigate, user, user.sub, auth0Domain, environment, bonbonApiDomain]);

    return (<ReactLoading type="balls" />);
}

export default withAuthenticationRequired(AuthRouter, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
