import { React, useEffect } from "react";
import ReactLoading from 'react-loading';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Link from "@mui/material/Link";
import Typography from '@mui/material/Typography';
import Form from './Form';

const New = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth0();
    useEffect(() => {
        const checkUser = async () => {
          if(!user) {
            navigate('/');
          }
          if(!user.email.endsWith("bonbon.tech")) {
            navigate('/account');
          }
        };
        checkUser();
    });
    return (
        <div>
            <div style={{ margin: "12px 0" }}>
              <Typography
                component={Link}
                sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
                variant="p"
                href={`/offers`}
              >
                All Offers
              </Typography>
               <Typography
                component={Link}
                sx={{ color: theme.palette.primary.main, textDecoration: "none" }}
                variant="p"
                href={`/offers`}
              >
                &nbsp;>&nbsp;
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.secondary,
                  textDecoration: "none",
                }}
                variant="p"
              >
                New Offer
              </Typography>
          </div>
        <Box sx={{
        borderRadius: theme.spacing(5),
        backgroundColor: `${theme.palette.primary.background}`,
        p: theme.spacing(7),
        my: theme.spacing(7),
        display: 'flex',
        maxWidth: '592px',
    }}>
        <Box sx={{
            border: `solid 1px ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            p: theme.spacing(5, 8),
            width: '100%',
        }}>
            <Typography variant="h4" component="h1">Create Offer</Typography>
            <Form />
        </Box>
    </Box></div>);
}

//export default Profile;

export default withAuthenticationRequired(New, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
