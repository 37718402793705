import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Formik, Form } from 'formik';
import environments from '../environments';
import * as yup from 'yup';
import axios from 'axios';

const environment = process.env.REACT_APP_ENVIRONMENT || "development";

const NewForm = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const validationSchema = yup.object({
        site_name: yup
            .string('Site Name *')
            .required('Site name is required')
            .matches(/\S+/, 'Site name is required'),
        domain: yup
            .string('Domain *')
            .required('Domain is required')
            .matches(/\S+/, 'Site name is required'),
        logo_url: yup
            .string('Logo URL *')
            .required('Logo URL is required')
            .matches(/\S+/, 'Logo URL is required'),
        privacy_url: yup
            .string('Privacy Policy URL *')
            .required('Privacy Policy URL is required')
            .matches(/\S+/, 'Privacy Policy URL is required'),
    });

    const pubMetadata = {site_name: "", domain: "", logo_url: "", privacy_url: ""};
    const submitPub = async (pubData) => {
        setSubmitting(true);
        console.log("pubData", pubData);
        const accessToken = await getAccessTokenSilently({
            audience: environments[environment].REACT_APP_AUDIENCE,
            scope: 'read:current_user update:current_user_metadata',
        });
        
        const publisher = await axios.post(
            `${environments[environment].REACT_APP_BONBON_API}/api/v1/publishers`,
            {
                pub: pubData.site_name,
                domain: pubData.domain,
                logo_url: pubData.logo_url,
                privacy_url: pubData.privacy_url
            },
            { headers: { Authorization: `Bearer ${accessToken}` } },
        );
        navigate(`/publishers/${publisher.data.pub_id}`);
    };

    return (<Formik
        initialValues={pubMetadata}
        validationSchema={validationSchema}
        onSubmit={submitPub}
    >
        {props => (
            <Form>
                <Stack>
                    <TextField
                    id="site_name"
                    name="site_name"
                    label="Site name *"
                    value={props.values.site_name}
                    onChange={props.handleChange}
                    error={props.touched.site_name && Boolean(props.errors.site_name)}
                    helperText={props.touched.site_name && props.errors.site_name}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="domain"
                    name="domain"
                    label="Domain *"
                    value={props.values.domain}
                    onChange={props.handleChange}
                    error={props.touched.domain && Boolean(props.errors.domain)}
                    helperText={props.touched.domain && props.errors.domain}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="logo_url"
                    name="logo_url"
                    label="Logo URL *"
                    value={props.values.logo_url}
                    onChange={props.handleChange}
                    error={props.touched.logo_url && Boolean(props.errors.logo_url)}
                    helperText={props.touched.logo_url && props.errors.logo_url}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="privacy_url"
                    name="privacy_url"
                    label="Privacy Policy URL *"
                    value={props.values.privacy_url}
                    onChange={props.handleChange}
                    error={props.touched.privacy_url && Boolean(props.errors.privacy_url)}
                    helperText={props.touched.privacy_url && props.errors.privacy_url}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        size="medium"
                        disabled={submitting}
                        sx={{
                            fontWeight: 'bold',
                            verticalAlign: 'center',
                        }}
                    >
                        Create Publisher<Typography sx={{pl: theme.spacing(2)}} component="span">&#x2794;</Typography>
                    </Button>
                </Stack>
            </Form>
        )}
    </Formik>);
}

export default withAuthenticationRequired(NewForm, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
