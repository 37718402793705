const environments = {
  staging: {
    REACT_APP_ENV: "staging",
    REACT_APP_AUTH_DOMAIN: "staging.sso.bonbon.tech",
    REACT_APP_CLIENTID: "CdWwzCbVpoxw1Tp7PkArmEi5mR0MPJza",
    REACT_APP_AUDIENCE: "https://bonbon-development.us.auth0.com/api/v2/",
    REACT_APP_BONBON_API: "https://bonbon-service-auth-staging.herokuapp.com",
  },
  production: {
    REACT_APP_ENV: "production",
    REACT_APP_AUTH_DOMAIN: "sso.bonbon.tech",
    REACT_APP_CLIENTID: "uJaKUjf8skiSrOLBRyKwYcVS3K6fCk7C",
    REACT_APP_AUDIENCE: "https://dev-c081z6lh431lz2hx.us.auth0.com/api/v2/",
    REACT_APP_BONBON_API: "https://bonbon-service-auth.herokuapp.com",
  },
  development: {
    REACT_APP_ENV: "production",
    REACT_APP_AUTH_DOMAIN: "sso.bonbon.tech",
    REACT_APP_CLIENTID: "uJaKUjf8skiSrOLBRyKwYcVS3K6fCk7C",
    REACT_APP_AUDIENCE: "https://dev-c081z6lh431lz2hx.us.auth0.com/api/v2/",
    REACT_APP_BONBON_API: "http://localhost:3010",
  }
}

module.exports = environments;
