import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Formik, Form } from 'formik';
import environments from '../environments';
import * as yup from 'yup';
import axios from 'axios';

const environment = process.env.REACT_APP_ENVIRONMENT || "development";

const NewForm = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const validationSchema = yup.object({
        offer_id: yup
            .string('Offer ID *')
            .required('Offer ID is required')
            .matches(/\S+/, 'Offer ID is required'),
        h1: yup
            .string('Heading 1 *')
            .required('Heading 1 is required')
            .matches(/\S+/, 'Heading 1 is required'),
        h2: yup
            .string('Heading 2 *')
            .required('Heading 2 is required')
            .matches(/\S+/, 'Heading 2 is required')
            .max(200, 'Heading 2 must be no longer than 200 characters'),
        button_text: yup
            .string('Button Text'),
        reward_label: yup
            .string('Reward Label *')
            .required('Reward Label is required')
            .matches(/\S+/, 'Reward Label is required'),
        image_url: yup
            .string('Image URL *')
            .required('Image URL is required')
            .matches(/\S+/, 'Image URL is required'),
    });

    const offerMetadata = {offer_id: "", h1: "", h2: "", reward_label: "", image_url: "", button_text: ""};
    const submitOffer = async (offerData) => {
        setSubmitting(true);
        console.log("offerData", offerData);
        const accessToken = await getAccessTokenSilently({
            audience: environments[environment].REACT_APP_AUDIENCE,
            scope: 'read:current_user update:current_user_metadata',
        });
        try{
            const offer = await axios.post(
                `${environments[environment].REACT_APP_BONBON_API}/api/v1/offers`,
                {
                    offer_id: offerData.offer_id,
                    h1: offerData.h1,
                    h2: offerData.h2,
                    button_text: offerData.button_text,
                    reward_label: offerData.reward_label,
                    image_url: offerData.image_url,
                },
                { headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                    }
                },
            );
            navigate(`/offers/${offer.data.id}`);
        } catch(e) {
            setSubmitting(false);
            alert(`there was an error creating your offer. ${e.message}`);
        }
    };

    return (<Formik
        initialValues={offerMetadata}
        validationSchema={validationSchema}
        onSubmit={submitOffer}
    >
        {props => (
            <Form>
                <Stack>
                    <TextField
                    id="offer_id"
                    name="offer_id"
                    label="Offer ID *"
                    value={props.values.offer_id}
                    onChange={props.handleChange}
                    error={props.touched.offer_id && Boolean(props.errors.offer_id)}
                    helperText={(props.touched.offer_id && props.errors.offer_id) || "ex: airpods_v1"}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="h1"
                    name="h1"
                    label="Heading 1 *"
                    value={props.values.h1}
                    onChange={props.handleChange}
                    error={props.touched.h1 && Boolean(props.errors.h1)}
                    helperText={(props.touched.h1 && props.errors.h1) || "ex: Win The New Apple Airpods Pro"}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="h2"
                    name="h2"
                    label="Heading 2 *"
                    value={props.values.h2}
                    onChange={props.handleChange}
                    error={props.touched.h2 && Boolean(props.errors.h2)}
                    helperText={(props.touched.h2 && props.errors.h2) || "Note: 'Create a free <b>::pubname::</b> account with Bonbon to enter.' automatically appended"}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="image_url"
                    name="image_url"
                    label="Image URL *"
                    value={props.values.image_url}
                    onChange={props.handleChange}
                    error={props.touched.image_url && Boolean(props.errors.image_url)}
                    helperText={props.touched.image_url && props.errors.image_url}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <TextField
                    id="reward_label"
                    name="reward_label"
                    label="Reward Label *"
                    value={props.values.reward_label}
                    onChange={props.handleChange}
                    error={props.touched.reward_label && Boolean(props.errors.reward_label)}
                    helperText={(props.touched.reward_label && props.errors.reward_label) || "Usage: 'You just earned another entry toward [Reward Label]'"}
                    sx={{mb: theme.spacing(5)}}
                    />

                    <TextField
                    id="button_text"
                    name="button_text"
                    label="Button Text"
                    value={props.values.button_text}
                    onChange={props.handleChange}
                    error={props.touched.button_text && Boolean(props.errors.button_text)}
                    helperText={(props.touched.button_text && props.errors.button_text) || "Default:'One-Click Sign Up'"}
                    sx={{mb: theme.spacing(5)}}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        size="medium"
                        disabled={submitting}
                        sx={{
                            fontWeight: 'bold',
                            verticalAlign: 'center',
                        }}
                    >
                        Create Offer<Typography sx={{pl: theme.spacing(2)}} component="span">&#x2794;</Typography>
                    </Button>
                </Stack>
            </Form>
        )}
    </Formik>);
}

export default withAuthenticationRequired(NewForm, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
