import ReactLoading from 'react-loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ProfileForm from './ProfileForm';
import Cobrand from './Cobrand';

const FinalizeAccount = () => {
    const theme = useTheme();

    return (<Stack sx={{
        borderRadius: theme.spacing(5),
        backgroundColor: `${theme.palette.primary.background}`,
        p: theme.spacing(7),
        my: theme.spacing(7),
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            p: theme.spacing(0),
            width: '100%',
            flexDirection: 'column',
        }
    }}>
        <Box sx={{
            borderBottom: `solid 1px ${theme.palette.primary.border}`,
            width: '100%',
            px: theme.spacing(0),
            py: theme.spacing(8),
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            }
        }}>
            <Cobrand />
        </Box>
        <Box sx={{
            border: `solid 1px ${theme.palette.primary.border}`,
            borderRadius: theme.spacing(3),
            p: theme.spacing(5, 8),
            [theme.breakpoints.down('md')]: {
                borderStyle: 'none',
                width: '100%',
            },
        }}>
            <Typography variant="h4">Email confirmed! Please complete your account.</Typography>
            <Typography component="h6" variant="body1" sx={{my: theme.spacing(4)}}>Help personalize your experience</Typography>
            <ProfileForm />
        </Box>
        <Box sx={{
            backgroundImage: 'url(/keyhole.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center 150px',
            textAlign: 'center',
            maxWidth: '572px',
            minWidth: '45%',
            ml: theme.spacing(7),
            py: theme.spacing(7),
            [theme.breakpoints.down('md')]: {
                display: 'none',
                width: '100%',
            }
        }}>
            <Box component="h2" sx={{
                width: '235px',
                mx: 'auto',
            }}>
                <Cobrand />
            </Box>
            <Typography component="p" sx={{
                maxWidth: '423px',
                mx: 'auto',
                mt: '340px',
            }}>Bonbon is the safe and secure login partner for your favorite sites.</Typography>
        </Box>
    </Stack>);
}

export default withAuthenticationRequired(FinalizeAccount, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
